@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap");

@import "swiper/swiper";

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --center-of-screen: calc(100vw / 12 * 6);
  --breakpoint-2xl: 2000px;
}

* {
  box-sizing: border-box;
}
@media only screen and (max-width: 765px) {
  body,
  html {
    overflow-x: hidden;
    max-width: 100%;
    -webkit-overflow-scrolling: touch;
    overscroll-behavior-y: none;
  }
  * {
    overscroll-behavior-y: none;
  }
}
/* Typography
============================================
*/
@layer base {
  html,
  body {
    padding: 0;
    margin: 0;
    font-family: "Source Sans Pro", sans-serif;
    max-width: 100vw;
    overflow-x: "hidden";
    overflow-y: auto;
    background-color: rgba(229, 229, 229, 1);
    &.modal-open {
      overflow-y: hidden !important;
      overscroll-behavior: none;
      touch-action: none;
      -webkit-overflow-scrolling: none;
    }
  }

  p ~ p {
    margin-top: 1.5em;
  }

  .underline-decoration {
    position: relative;
    &:after {
      content: "";
      display: block;
      position: absolute;
      width: 33%;
      padding-top: 1em;
      top: 100%;
      border-bottom: 1px solid currentColor;
    }
  }

  #root {
    max-width: 100vw;
    overflow-x: hidden;
  }

  a {
    color: inherit;
    text-decoration: none;

    &:hover {
      .cursor-inner {
        color: #28a745;
        width: 30px;
        height: 30px;
      }
    }
  }
  .font-primary {
    font-family: "Source Sans Pro", sans-serif;
  }
  .font-secondary {
    font-family: "Cabin", sans-serif;
  }
  h1,
  .h1 {
    @apply text-4xl;
    /*@apply font-secondary;*/
    line-height: 1.06;
  }
  h2,
  .h2 {
    @apply font-light;
    @apply text-3xl;
  }
  h3,
  .h3 {
    @apply font-light;
    @apply text-2xl;
  }

  h4,
  .h4 {
    @apply text-xl;
  }

  h5,
  .h5 {
  }

  h6,
  .h6 {
  }

  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4,
  h5,
  .h5,
  h6,
  .h6 {
  }

  .h2 + .subtitle {
    @apply text-xl;
  }
  /** 
  *  Containers
  */
  .container {
    @apply mx-auto;
  }
  /**
  *  Utils
  */
  .absolute-center {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .absolute-center-h {
    left: 50%;
    transform: translateX(-50%);
  }
  .absolute-center-y {
    top: 50%;
    transform: translateY(-50%);
  }

  .right-line-grey::after {
    content: " ";
    width: 80px;
    height: 1px;
    background-color: #565656;
    position: absolute;
    right: -85px;
    top: 25px;
    border-radius: 50px;
  }

  /* Chat Bot */
  #zsiq_agtpic {
    img {
      content: url("../assets/chatbot-image.webp");
    }
    -webkit-box-shadow: 0px 3px 20px 5px rgb(80 80 80 / 40%);
    box-shadow: 0px 3px 20px 5px rgb(80 80 80 / 40%);
    border-radius: 100%;
  }

  #chatbot ~ .zsiq_custommain,
  #chatbot ~ .zsiq_floatmain {
    z-index: 1000 !important;
  }

  #zsiq_float {
    position: relative;
  }
  #zsiq_float:after,
  #zsiq_float:before {
    content: "";
    position: absolute;
    border-radius: 50%;
    background-color: #c3c3c350;
    z-index: -1;
    top: 5px;
    left: 5px;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    animation: ping 2s cubic-bezier(0, 0, 0.1, 1) infinite;
  }

  #content.home-section-active .home-section-link,
  #content.why-us-section-active .why-us-section-link,
  #content.services-section-active .services-section-link,
  #content.about-us-section-active .about-us-section-link,
  #content.success-cases-section-active .success-cases-section-link,
  #content.partners-section-active .partners-section-link,
  #content.awards-section-active .awards-section-link,
  #content.blog-section-active .blog-section-link,
  #content.where-are-we-section-active .where-are-we-section-link {
    pointer-events: auto;
    cursor: pointer;
    color: #ffffff;
    span {
      display: inline-block;
      padding-left: 80px;
      position: relative;
      &:before {
        content: "";
        width: 61px;
        height: 1px;
        background-color: #fff;
        position: absolute;
        bottom: 10px;
        right: 30px;
      }
    }
  }
  #partners-section {
    background-image: url("../assets/our-partners-bg.webp");
    background-repeat: no-repeat;
    background-position: right top;
    background-size: contain;
  }
  #content.scrolled #menu-toggle,
  .modal-open #menu-toggle {
    @media screen and (max-width: 1023px) {
      top: 2.75rem;
    }
    @media screen and (min-width: 1024px) {
      right: 0;
    }
  }
  #services-section {
    min-height: fit-content;
  }

  .modal-open {
    overflow: hidden !important;
  }

  .modal-content {
    overflow-x: hidden;
  }

  .social-link {
    transition: opacity 0.3s ease;
    position: relative;
    height: 0;
    min-width: 2rem;
    a {
      padding-bottom: 100%;
      top: 0;
      left: 0;
      width: 100%;
      display: block;
    }
    height: 2rem;
    & img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .social-link:before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    border: 1px solid #ffffff;
    transition: transform 0.3s ease;
    position: absolute;
    transform: scale(0.9);
  }
  .social-link:hover {
    opacity: 0.9;
    &:before {
      transform: scale(1.3);
    }
  }

  select > option {
    color: #171717;
  }

  /* Animations */

  // Mouse pointer
  .mousecursor {
    position: fixed;
    left: 0;
    top: 0;
    pointer-events: none;
    z-index: 99999;
  }

  .cursor-outer {
    opacity: 0.2;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    border: 2px solid white;
    mix-blend-mode: difference;
  }

  .cursor-inner {
    opacity: 0.6;
    mix-blend-mode: difference;
    margin-left: -2px;
    margin-top: -2px;
  }

  .menu-items {
    padding-left: 5rem;
    padding-right: 2rem;
    opacity: 1;
    background-color: #2e2e2e;
    background-image: url("../assets/main-menu-bg.svg");
    @media screen and (max-width: 1023px) {
      background-position-y: bottom;
      padding-left: 1rem;
    }
  }

  .contact-us {
    background-color: #171717;
  }

  @keyframes glitch-anim-1 {
    0% {
      opacity: 1;
      transform: translate3d(var(--gap-horizontal), 0, 0);
      -webkit-clip-path: polygon(0 2%, 100% 2%, 100% 5%, 0 5%);
      clip-path: polygon(0 2%, 100% 2%, 100% 5%, 0 5%);
    }
    2% {
      -webkit-clip-path: polygon(0 15%, 100% 15%, 100% 15%, 0 15%);
      clip-path: polygon(0 15%, 100% 15%, 100% 15%, 0 15%);
    }
    4% {
      -webkit-clip-path: polygon(0 10%, 100% 10%, 100% 20%, 0 20%);
      clip-path: polygon(0 10%, 100% 10%, 100% 20%, 0 20%);
    }
    6% {
      -webkit-clip-path: polygon(0 1%, 100% 1%, 100% 2%, 0 2%);
      clip-path: polygon(0 1%, 100% 1%, 100% 2%, 0 2%);
    }
    8% {
      -webkit-clip-path: polygon(0 33%, 100% 33%, 100% 33%, 0 33%);
      clip-path: polygon(0 33%, 100% 33%, 100% 33%, 0 33%);
    }
    10% {
      -webkit-clip-path: polygon(0 44%, 100% 44%, 100% 44%, 0 44%);
      clip-path: polygon(0 44%, 100% 44%, 100% 44%, 0 44%);
    }
    12% {
      -webkit-clip-path: polygon(0 50%, 100% 50%, 100% 20%, 0 20%);
      clip-path: polygon(0 50%, 100% 50%, 100% 20%, 0 20%);
    }
    14% {
      -webkit-clip-path: polygon(0 70%, 100% 70%, 100% 70%, 0 70%);
      clip-path: polygon(0 70%, 100% 70%, 100% 70%, 0 70%);
    }
    16% {
      -webkit-clip-path: polygon(0 80%, 100% 80%, 100% 80%, 0 80%);
      clip-path: polygon(0 80%, 100% 80%, 100% 80%, 0 80%);
    }
    18% {
      -webkit-clip-path: polygon(0 50%, 100% 50%, 100% 55%, 0 55%);
      clip-path: polygon(0 50%, 100% 50%, 100% 55%, 0 55%);
    }
    20% {
      -webkit-clip-path: polygon(0 70%, 100% 70%, 100% 80%, 0 80%);
      clip-path: polygon(0 70%, 100% 70%, 100% 80%, 0 80%);
    }
    21.9% {
      opacity: 1;
      transform: translate3d(var(--gap-horizontal), 0, 0);
    }
    22%,
    100% {
      opacity: 0;
      transform: translate3d(0, 0, 0);
      -webkit-clip-path: polygon(0 0, 0 0, 0 0, 0 0);
      clip-path: polygon(0 0, 0 0, 0 0, 0 0);
    }
  }
  @keyframes glitch-anim-2 {
    0% {
      opacity: 1;
      transform: translate3d(calc(-1 * var(--gap-horizontal)), 0, 0);
      -webkit-clip-path: polygon(0 25%, 100% 25%, 100% 30%, 0 30%);
      clip-path: polygon(0 25%, 100% 25%, 100% 30%, 0 30%);
    }
    3% {
      -webkit-clip-path: polygon(0 3%, 100% 3%, 100% 3%, 0 3%);
      clip-path: polygon(0 3%, 100% 3%, 100% 3%, 0 3%);
    }
    5% {
      -webkit-clip-path: polygon(0 5%, 100% 5%, 100% 20%, 0 20%);
      clip-path: polygon(0 5%, 100% 5%, 100% 20%, 0 20%);
    }
    7% {
      -webkit-clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
      clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
    }
    9% {
      -webkit-clip-path: polygon(0 40%, 100% 40%, 100% 40%, 0 40%);
      clip-path: polygon(0 40%, 100% 40%, 100% 40%, 0 40%);
    }
    11% {
      -webkit-clip-path: polygon(0 52%, 100% 52%, 100% 59%, 0 59%);
      clip-path: polygon(0 52%, 100% 52%, 100% 59%, 0 59%);
    }
    13% {
      -webkit-clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
      clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
    }
    15% {
      -webkit-clip-path: polygon(0 75%, 100% 75%, 100% 75%, 0 75%);
      clip-path: polygon(0 75%, 100% 75%, 100% 75%, 0 75%);
    }
    17% {
      -webkit-clip-path: polygon(0 65%, 100% 65%, 100% 40%, 0 40%);
      clip-path: polygon(0 65%, 100% 65%, 100% 40%, 0 40%);
    }
    19% {
      -webkit-clip-path: polygon(0 45%, 100% 45%, 100% 50%, 0 50%);
      clip-path: polygon(0 45%, 100% 45%, 100% 50%, 0 50%);
    }
    20% {
      -webkit-clip-path: polygon(0 14%, 100% 14%, 100% 33%, 0 33%);
      clip-path: polygon(0 14%, 100% 14%, 100% 33%, 0 33%);
    }
    21.9% {
      opacity: 1;
      transform: translate3d(calc(-1 * var(--gap-horizontal)), 0, 0);
    }
    22%,
    100% {
      opacity: 0;
      transform: translate3d(0, 0, 0);
      -webkit-clip-path: polygon(0 0, 0 0, 0 0, 0 0);
      clip-path: polygon(0 0, 0 0, 0 0, 0 0);
    }
  }

  @keyframes glitch-anim-3 {
    0% {
      opacity: 1;
      transform: translate3d(0, calc(-1 * var(--gap-vertical)), 0) scale3d(-1, -1, 1);
      -webkit-clip-path: polygon(0 1%, 100% 1%, 100% 3%, 0 3%);
      clip-path: polygon(0 1%, 100% 1%, 100% 3%, 0 3%);
    }
    1.5% {
      -webkit-clip-path: polygon(0 10%, 100% 10%, 100% 9%, 0 9%);
      clip-path: polygon(0 10%, 100% 10%, 100% 9%, 0 9%);
    }
    2% {
      -webkit-clip-path: polygon(0 5%, 100% 5%, 100% 6%, 0 6%);
      clip-path: polygon(0 5%, 100% 5%, 100% 6%, 0 6%);
    }
    2.5% {
      -webkit-clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
      clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
    }
    3% {
      -webkit-clip-path: polygon(0 10%, 100% 10%, 100% 10%, 0 10%);
      clip-path: polygon(0 10%, 100% 10%, 100% 10%, 0 10%);
    }
    5% {
      -webkit-clip-path: polygon(0 30%, 100% 30%, 100% 25%, 0 25%);
      clip-path: polygon(0 30%, 100% 30%, 100% 25%, 0 25%);
    }
    5.5% {
      -webkit-clip-path: polygon(0 15%, 100% 15%, 100% 16%, 0 16%);
      clip-path: polygon(0 15%, 100% 15%, 100% 16%, 0 16%);
    }
    7% {
      -webkit-clip-path: polygon(0 40%, 100% 40%, 100% 39%, 0 39%);
      clip-path: polygon(0 40%, 100% 40%, 100% 39%, 0 39%);
    }
    8% {
      -webkit-clip-path: polygon(0 20%, 100% 20%, 100% 21%, 0 21%);
      clip-path: polygon(0 20%, 100% 20%, 100% 21%, 0 21%);
    }
    9% {
      -webkit-clip-path: polygon(0 60%, 100% 60%, 100% 55%, 0 55%);
      clip-path: polygon(0 60%, 100% 60%, 100% 55%, 0 55%);
    }
    10.5% {
      -webkit-clip-path: polygon(0 30%, 100% 30%, 100% 31%, 0 31%);
      clip-path: polygon(0 30%, 100% 30%, 100% 31%, 0 31%);
    }
    11% {
      -webkit-clip-path: polygon(0 70%, 100% 70%, 100% 69%, 0 69%);
      clip-path: polygon(0 70%, 100% 70%, 100% 69%, 0 69%);
    }
    13% {
      -webkit-clip-path: polygon(0 40%, 100% 40%, 100% 41%, 0 41%);
      clip-path: polygon(0 40%, 100% 40%, 100% 41%, 0 41%);
    }
    14% {
      -webkit-clip-path: polygon(0 80%, 100% 80%, 100% 75%, 0 75%);
      clip-path: polygon(0 80%, 100% 80%, 100% 75%, 0 75%);
    }
    14.5% {
      -webkit-clip-path: polygon(0 50%, 100% 50%, 100% 51%, 0 51%);
      clip-path: polygon(0 50%, 100% 50%, 100% 51%, 0 51%);
    }
    15% {
      -webkit-clip-path: polygon(0 90%, 100% 90%, 100% 90%, 0 90%);
      clip-path: polygon(0 90%, 100% 90%, 100% 90%, 0 90%);
    }
    16% {
      -webkit-clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
      clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
    }
    18% {
      -webkit-clip-path: polygon(0 100%, 100% 100%, 100% 99%, 0 99%);
      clip-path: polygon(0 100%, 100% 100%, 100% 99%, 0 99%);
    }
    20% {
      -webkit-clip-path: polygon(0 70%, 100% 70%, 100% 71%, 0 71%);
      clip-path: polygon(0 70%, 100% 70%, 100% 71%, 0 71%);
    }
    21.9% {
      opacity: 1;
      transform: translate3d(0, calc(-1 * var(--gap-vertical)), 0) scale3d(-1, -1, 1);
    }
    22%,
    100% {
      opacity: 0;
      transform: translate3d(0, 0, 0);
      -webkit-clip-path: polygon(0 0, 0 0, 0 0, 0 0);
      clip-path: polygon(0 0, 0 0, 0 0, 0 0);
    }
  }
  @keyframes glitch-anim-text {
    0% {
      transform: translate3d(calc(-1 * var(--gap-horizontal)), 0, 0) scale3d(-1, -1, 1);
      -webkit-clip-path: polygon(0 20%, 100% 20%, 100% 21%, 0 21%);
      clip-path: polygon(0 20%, 100% 20%, 100% 21%, 0 21%);
    }
    2% {
      -webkit-clip-path: polygon(0 33%, 100% 33%, 100% 33%, 0 33%);
      clip-path: polygon(0 33%, 100% 33%, 100% 33%, 0 33%);
    }
    4% {
      -webkit-clip-path: polygon(0 44%, 100% 44%, 100% 44%, 0 44%);
      clip-path: polygon(0 44%, 100% 44%, 100% 44%, 0 44%);
    }
    5% {
      -webkit-clip-path: polygon(0 50%, 100% 50%, 100% 20%, 0 20%);
      clip-path: polygon(0 50%, 100% 50%, 100% 20%, 0 20%);
    }
    6% {
      -webkit-clip-path: polygon(0 70%, 100% 70%, 100% 70%, 0 70%);
      clip-path: polygon(0 70%, 100% 70%, 100% 70%, 0 70%);
    }
    7% {
      -webkit-clip-path: polygon(0 80%, 100% 80%, 100% 80%, 0 80%);
      clip-path: polygon(0 80%, 100% 80%, 100% 80%, 0 80%);
    }
    8% {
      -webkit-clip-path: polygon(0 50%, 100% 50%, 100% 55%, 0 55%);
      clip-path: polygon(0 50%, 100% 50%, 100% 55%, 0 55%);
    }
    9% {
      -webkit-clip-path: polygon(0 70%, 100% 70%, 100% 80%, 0 80%);
      clip-path: polygon(0 70%, 100% 70%, 100% 80%, 0 80%);
    }
    9.9% {
      transform: translate3d(calc(-1 * var(--gap-horizontal)), 0, 0) scale3d(-1, -1, 1);
    }
    10%,
    100% {
      transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
      -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    }
  }
  /* Flash */

  @keyframes glitch-anim-flash {
    0%,
    5% {
      opacity: 0.2;
      transform: translate3d(var(--gap-horizontal), var(--gap-vertical), 0);
    }
    5.5%,
    100% {
      opacity: 0;
      transform: translate3d(0, 0, 0);
    }
  }

  li.itemmenu .colorbtn {
    margin-top: 0px !important;
    font-size: 13px !important;
    text-transform: uppercase;
    min-width: 100px !important;
    cursor: pointer;
  }
  li.itemmenu.item5- {
    margin-top: 17px;
  }

  li.itemmenu .colorbtn .btnContact {
    padding: 11px 22px !important;
    font-size: 11px;
    line-height: 11px;
    text-transform: uppercase;
    letter-spacing: 2px;
    min-width: 100px !important;
  }

  .main-sticky li.itemmenu .colorbtn .btnContact,
  .main-sticky li.itemmenu .colorbtn:before {
    background: none !important;
  }

  .colorbtn {
    padding: 1px;
    margin-top: 20px !important;
    display: block;
    float: left;
    font-size: 18px;
    border-radius: 60px;
    font-weight: bold;
    -webkit-transition: 0.4s all ease;
    -moz-transition: 0.4ss all ease;
    cursor: pointer;
    position: relative;
    overflow: hidden !important;
    background: none;
    animation-name: glitch-anim-text;
    animation-duration: var(--time-anim);
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-delay: calc(var(--delay-anim) + var(--time-anim) * 0.1);
    min-width: 167px;
  }
  .colorbtnai {
    padding: 1px;
    margin-top: 20px !important;
    display: block;
    float: left;
    font-size: 18px;
    border-radius: 60px;
    font-weight: bold;
    -webkit-transition: 0.4s all ease;
    -moz-transition: 0.4ss all ease;
    cursor: pointer;
    position: relative;
    overflow: hidden !important;
    background: none;
    animation-name: glitch-anim-text;
    animation-duration: var(--time-anim);
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-delay: calc(var(--delay-anim) + var(--time-anim) * 0.1);
    min-width: 167px;
  }

  .colorbtn:hover,
  .colorbtnai:hover {
    animation-name: none;
  }
  .colorbtn:before,
  .colorbtnai:before {
    content: "";
    position: absolute;
    background: rgba(233, 46, 24, 1);
    background: -moz-linear-gradient(-45deg, rgba(233, 46, 24, 1) 0%, rgba(253, 177, 0, 1) 33%, rgba(44, 112, 245, 1) 66%, rgba(26, 155, 61, 1) 100%);
    background: -webkit-gradient(left top, right bottom, color-stop(0%, rgba(233, 46, 24, 1)), color-stop(33%, rgba(253, 177, 0, 1)), color-stop(66%, rgba(44, 112, 245, 1)), color-stop(100%, rgba(26, 155, 61, 1)));
    background: -webkit-linear-gradient(-45deg, rgba(233, 46, 24, 1) 0%, rgba(253, 177, 0, 1) 33%, rgba(44, 112, 245, 1) 66%, rgba(26, 155, 61, 1) 100%);
    background: -o-linear-gradient(-45deg, rgba(233, 46, 24, 1) 0%, rgba(253, 177, 0, 1) 33%, rgba(44, 112, 245, 1) 66%, rgba(26, 155, 61, 1) 100%);
    background: -ms-linear-gradient(-45deg, rgba(233, 46, 24, 1) 0%, rgba(253, 177, 0, 1) 33%, rgba(44, 112, 245, 1) 66%, rgba(26, 155, 61, 1) 100%);
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    transform: rotate(0deg);
    -webkit-transition: 0.3s all ease;
    -moz-transition: 0.3s all ease;
  }
  .colorbtn:hover:before,
  .colorbtnai:hover:before {
    transform: rotate(180deg);
  }
  .colorbtn.tono2 .btnContact,
  .colorbtnai.tono2 .btnContact {
    background: #efefef;
  }

  .colorbtn {
    padding: 1px;
    margin-top: 20px !important;
    display: block;
    float: left;
    font-size: 18px;
    border-radius: 60px;
    font-weight: bold;
    -webkit-transition: 0.4s all ease;
    -moz-transition: 0.4ss all ease;
    cursor: pointer;
    position: relative;
    overflow: hidden !important;
    background: none;
    animation-name: glitch-anim-text;
    animation-duration: var(--time-anim);
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-delay: calc(var(--delay-anim) + var(--time-anim) * 0.1);
    min-width: 167px;
  }
  .colorbtnai {
    padding: 1px;
    margin-top: 20px !important;
    display: block;
    float: left;
    font-size: 18px;
    border-radius: 60px;
    font-weight: bold;
    -webkit-transition: 0.4s all ease;
    -moz-transition: 0.4ss all ease;
    cursor: pointer;
    position: relative;
    overflow: hidden !important;
    background: none;
    animation-name: glitch-anim-text;
    animation-duration: var(--time-anim);
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-delay: calc(var(--delay-anim) + var(--time-anim) * 0.1);
    min-width: 167px;
  }

  .colorbtn:hover,
  .colorbtnai:hover {
    animation-name: none;
  }
  .colorbtn:before,
  .colorbtnai:before {
    content: "";
    position: absolute;
    background: rgba(233, 46, 24, 1);
    background: -moz-linear-gradient(-45deg, rgba(233, 46, 24, 1) 0%, rgba(253, 177, 0, 1) 33%, rgba(44, 112, 245, 1) 66%, rgba(26, 155, 61, 1) 100%);
    background: -webkit-gradient(left top, right bottom, color-stop(0%, rgba(233, 46, 24, 1)), color-stop(33%, rgba(253, 177, 0, 1)), color-stop(66%, rgba(44, 112, 245, 1)), color-stop(100%, rgba(26, 155, 61, 1)));
    background: -webkit-linear-gradient(-45deg, rgba(233, 46, 24, 1) 0%, rgba(253, 177, 0, 1) 33%, rgba(44, 112, 245, 1) 66%, rgba(26, 155, 61, 1) 100%);
    background: -o-linear-gradient(-45deg, rgba(233, 46, 24, 1) 0%, rgba(253, 177, 0, 1) 33%, rgba(44, 112, 245, 1) 66%, rgba(26, 155, 61, 1) 100%);
    background: -ms-linear-gradient(-45deg, rgba(233, 46, 24, 1) 0%, rgba(253, 177, 0, 1) 33%, rgba(44, 112, 245, 1) 66%, rgba(26, 155, 61, 1) 100%);
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    transform: rotate(0deg);
    transition: 0.3s all ease;
    transition: 0.3s all ease;
  }
  .colorbtn:hover:before,
  .colorbtnai:hover:before {
    transform: rotate(180deg);
  }
  .colorbtn.tono2 .btnContact,
  .colorbtnai.tono2 .btnContact {
    background: #efefef;
  }

  .btnContact {
    padding: 15px 32px;
    display: block;
    float: left;
    font-size: 18px;
    border-radius: 60px;
    font-weight: bold;
    -webkit-transition: 0.4s all ease-out;
    -moz-transition: 0.4s all ease-out;
    cursor: pointer;
    position: relative;
    background: #f6f4f4;
    min-width: 165px;
    text-align: center;
  }
}
